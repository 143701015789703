<script>
import Vue from 'vue'
import { currency } from '@maxsystems/ui/vue/filters'
import LoadingSkeleton from '../LoadingSkeleton.vue'
import { auth, authData, client, withQuery } from '@maxsystems/client'
import getAppraisalBookouts from './getAppraisalBookouts.gql'
import getInventoryBookouts from './getInventoryBookouts.gql'
import updateAppraisalDisclosure from './updateAppraisalDisclosure.gql'
import updateInventoryDisclosure from './updateInventoryDisclosure.gql'

export default {
  name: 'ACVWidget',
  components: {
    LoadingSkeleton
  },
  filters: {
    currency
  },
  props: {
    /* Appraisal or Inventory Id */
    id: {
      required: true,
      type: [Number, String]
    },
    env: {
      type: String,
      default: null // it defaults to production when not specified
    },
    type: {
      type: String,
      default: 'appraisal',
      validator: value => ['appraisal', 'inventory'].includes(value)
    }
  },
  data: ({ type }) => {
    const actions = {
      inventory: {
        get: getInventoryBookouts,
        update: updateInventoryDisclosure
      },
      appraisal: {
        get: getAppraisalBookouts,
        update: updateAppraisalDisclosure
      }
    }

    return {
      authData,
      actions: {
        getBookouts: withQuery(actions[type].get),
        updateDisclosures: withQuery(actions[type].update)
      },
      isAuthenticating: false,
      error: false,
      vin: null,
      odometer: null,
      updatedAt: null,
      values: {}
    }
  },
  computed: {
    loading: vm => vm.isAuthenticating ||
      vm.actions.getBookouts.isPending() ||
      vm.actions.updateDisclosures.isPending(),
    isLoggedIn: ({ authData: { isLoggedIn } }) => isLoggedIn,
    fallback: () => 'Not Available for this Vehicle',
    retail: vm => vm.values?.retail?.value,
    wholesale: vm => vm.values.wholesale?.value,
    guarantee: vm => vm.values.guarantee?.value,
    disclosureUrl: vm =>
      `https://${vm.linkEnv}/${vm.type === 'appraisal' ? 'appraisals' : 'inventory'}/${vm.id}/disclosures`,
    haveDisclosures: vm => vm.values.wholesale,
    formattedUpdatedAt: vm => {
      const date = new Date(vm.updatedAt)
      const options = { year: 'numeric', month: 'short', day: 'numeric' }
      const formattedDate = date.toLocaleDateString('en-US', options)
      return formattedDate
    },
    linkEnv: ({ env }) => {
      if (env === 'dev') return 'dev.my.max.auto'
      if (env === 'stage') return 'stage.my.max.auto'
      return 'my.max.auto'
    },
    ssoUrl: ({ env }) => {
      const suffix = (env === 'dev' || env === 'stage') ? env : ''
      return `https://sso.max${suffix}.firstlook.biz`
    },
    odometerValue: ({ odometer }) => odometer === null || odometer === undefined || odometer === 0 ? 1 : odometer,
    startDate: () => {
      const today = new Date()
      today.setDate(today.getDate() - 90)
      return today.toISOString().substring(0, 10)
    },
    endDate: () => new Date().toISOString().substring(0, 10),
    marketReportLink: ({ startDate, endDate, odometerValue, vin }) =>
    `https://app.acvauctions.com/market-report?tab=vin&vin=${vin}&odometer=${odometerValue}&startDate=${startDate}&endDate=${endDate}`
  },
  async created () {
    if (this.env) {
      client.config({ env: this.env })
      auth._data.config.ssoHost = this.ssoUrl
    }

    this.isAuthenticating = true
    await this.isAuthenticated()
    this.isAuthenticating = false
    await this.fetchBookouts()
  },
  methods: {
    async updateDisclosures () {
      const input = {
        updateDisclosure: [
          {
            id: this.id,
            question: 'vin',
            answer: this.vin
          }
        ]
      }
      await this.actions.updateDisclosures.fetch({ input })
      await this.fetchBookouts()
    },
    async fetchBookouts () {
      const { data, errors } = await this.actions.getBookouts.fetch({ id: this.id }) || {}

      if (!data || errors) {
        this.error = 'Failed to load values for this vehicle'
        return
      }
      const { showGuarantee, showRetail, showWholesale } = data?.[this.type]?.dealer || {}
      const path = this.type === 'appraisal' ? 'appraisalBookOut' : 'inventoryBookOut'
      const acvValues = data?.[path]?.[0]?.bookOutData

      if (!acvValues?.length || (!showGuarantee && !showRetail && !showWholesale)) {
        this.error = 'Values are not available for this vehicle'
        return
      }

      this.updatedAt = data?.[path]?.[0]?.updatedAt
      this.vin = data[this.type].vehicle.vin
      this.odometer = data[this.type].vehicle?.odometer?.value

      const conditionTypeMap = {
        'ACV Guarantee': 'guarantee',
        'Retail Estimate': 'retail',
        'Wholesale Offer': 'wholesale'
      }

      const values = acvValues.reduce((acc, value) => {
        const type = conditionTypeMap[value.condition]
        if (type) {
          acc[type] = value?.valuations?.find(
            valuation => valuation.desc === 'Final Value'
          )?.value
        }
        return acc
      }, {
        guarantee: null,
        retail: null,
        wholesale: null
      })

      Object.entries({
        guarantee: showWholesale && showGuarantee,
        retail: showRetail,
        wholesale: showWholesale
      }).forEach(([type, isVisible]) => {
        if (isVisible) {
          this.$set(this.values, type, { value: values[type] })
        }
      })
    },
    async isAuthenticated () {
      if (this.isLoggedIn) return true

      Vue.use(auth)
      auth.setup()

      return new Promise(resolve => {
        this.$watch('isLoggedIn', isLoggedIn => {
          if (!isLoggedIn) return
          resolve(true)
        }, { immediate: true })
      })
    }
  }
}
</script>

<template>
  <div class="acv-widget">
    <div
      v-if="error"
      class="acv-widget__row"
    >
      <div class="acv-widget__col">
        <p>{{ error }}</p>
      </div>
    </div>
    <div v-else>
      <div
        v-if="loading"
        class="acv-widget__row"
      >
        <div class="acv-widget__col">
          <LoadingSkeleton
            width="110"
            height="20"
            class="mb-1"
          />
          <LoadingSkeleton
            width="110"
            height="36"
          />
        </div>
        <div class="acv-widget__col">
          <LoadingSkeleton
            width="110"
            height="20"
            class="mb-1"
          />
          <LoadingSkeleton
            width="110"
            height="36"
          />
        </div>
        <div class="acv-widget__col">
          <LoadingSkeleton
            width="110"
            height="20"
            class="mb-1"
          />
          <LoadingSkeleton
            width="110"
            height="36"
          />
        </div>
      </div>
      <div
        v-else
        class="acv-widget__row"
      >
        <div
          v-if="values.retail"
          class="acv-widget__col"
        >
          <div class="acv-widget__label">
            <strong class="label">ACV MAX Retail</strong>
          </div>
          <div class="acv-widget__value">
            <strong
              v-if="retail && retail > (wholesale || 0)"
              class="value"
            >{{ retail | currency }}</strong>
            <span
              v-else
              class="fallback"
            >{{ fallback }}</span>
          </div>
        </div>
        <div
          v-if="values.wholesale"
          class="acv-widget__col"
        >
          <div class="acv-widget__label">
            <strong class="label">ACV Wholesale</strong>
          </div>
          <div class="acv-widget__value">
            <strong
              v-if="wholesale"
              class="value"
            >{{ wholesale | currency }}</strong>
            <span
              v-else
              class="fallback"
            >{{ fallback }}</span>
          </div>
        </div>
        <div
          v-if="values.guarantee"
          class="acv-widget__col"
        >
          <div class="acv-widget__label">
            <strong class="label">ACV Guarantee</strong>
          </div>
          <div class="acv-widget__value">
            <strong
              v-if="guarantee"
              class="value"
            >{{ guarantee | currency }}</strong>
            <span
              v-else
              class="fallback"
            >{{ fallback }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="acv-widget__footer"
      >
        <div>
          <LoadingSkeleton
            width="244"
            height="30"
          />
        </div>
        <LoadingSkeleton
          width="180"
          height="30"
        />
        <div>
          <LoadingSkeleton
            width="160"
            height="30"
          />
        </div>
      </div>
      <div
        v-else
        class="acv-widget__footer"
      >
        <div class="acv-widget__info">
          <a
            class="acv-widget__button"
            @click.prevent="updateDisclosures"
          >
            Refresh
          </a>
          <span
            v-if="updatedAt"
            class="date"
          >Last Updated: {{ formattedUpdatedAt }}</span>
        </div>
        <a
          v-if="vin"
          class="acv-widget__button"
          :href="marketReportLink"
          rel="noopener"
          target="_blank"
        >
          ACV Market Report
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
          ><path
            fill="#1D3865"
            d="M9.5 10.083h-7a.585.585 0 0 1-.583-.583v-7c0-.32.262-.583.583-.583h2.917c.32 0 .583-.263.583-.584A.585.585 0 0 0 5.417.75h-3.5C1.269.75.75 1.275.75 1.917v8.166a1.17 1.17 0 0 0 1.167 1.167h8.166a1.17 1.17 0 0 0 1.167-1.167v-3.5A.585.585 0 0 0 10.667 6a.585.585 0 0 0-.584.583V9.5c0 .32-.262.583-.583.583zm-2.333-8.75c0 .321.262.584.583.584h1.51L3.936 7.242a.58.58 0 1 0 .823.823l5.325-5.326V4.25c0 .32.263.583.584.583.32 0 .583-.262.583-.583V.75h-3.5a.585.585 0 0 0-.583.583z"
          /></svg>
        </a>
        <a
          v-if="haveDisclosures"
          :href="disclosureUrl"
          target="_blank"
          class="acv-widget__button acv-widget__button--primary"
        >
          Update Condition
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.acv-widget {
  background-color: #FFF;
  border-radius: 5px;
  color: #000;
  display: flex;
  flex-flow: column;
  font-family: system-ui;

  &__row {
    display: flex;
    justify-content: space-around;
    margin: 24px 0;
  }

  &__col {
    align-items: center;
    display: flex;
    flex-flow: column;
    min-height: 60px;
    min-width: 180px;
    text-align: center;
  }

  .mb-1 {
    margin-bottom: 6px;
  }

  &__label {
    .label {
      color: #000;
      font-size: 14px;
      font-weight: 700;
    }
  }

  &__value {
    align-items: center;
    display: flex;
    height: 36px;

    .value {
      color: #000;
      font-size: 24px;
      font-weight: 500;
    }

    .fallback {
      color: #55575C;
      font-size: 14px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 8px 8px;
  }

  &__info {
    align-items: center;
    display: flex;

    .date {
      color: #55575C;
      display: inline-block;
      font-size: 14px;
      margin-left: 8px;
    }
  }

  &__button {
    align-self: flex-start;
    appearance: none;
    background-color: #E9EEF7;
    border: none;
    border-radius: 100px;
    color: #315084;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 22px;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      box-shadow: 0 1px 3px 1px #00000026;
      box-shadow: 0 1px 2px 0 #0000004D;
    }

    &:active {
      background-color: #D8DFEC;
      box-shadow: none;
    }

    &--primary {
      background-color: #315084;
      color: #FFF;

      &:hover {
        background-color: #415E8E;
      }

      &:active {
        background-color: #506490;
      }
    }
  }
}
</style>
